.blue-table {
  /deep/ {
    th {
      height: auto;
      .cell {
        background-color: $future-blue;
        color: #fff;
        border-radius: 10px 10px 0 0;
        width: 98%;
      }
    }

    .el-table__body-wrapper {
      background-color: white;
    }
  }
}
