#depositBonus {
  .banner-container {
    position: relative;
    text-align: center;
    min-height: 255px;
    .banner-image {
      width: 100%;
    }
    .new-banner-image {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 0;
    }
  }
  h3 {
    margin-bottom: 28px;
    text-align: center;
    font-size: 25px;
    color: $blue;
    text-transform: uppercase;
    &:after {
      content: '';
      width: 181px;
      height: 4px;
      margin: 16px auto 0;
      display: block;
      background-color: $primary;
    }
  }
  .over-bonus-alert {
    font-size: 16px;
    color: #7a8190;
    margin: 32px 50px 0;
  }
  .meta-box-magin {
    margin-top: 90px;
  }
  .meta-box {
    padding: 75px 115px 62px 115px;
    border-radius: 8px;
    background-color: $white;
    margin: 0 50px;
    /deep/ {
      h3 {
        margin-bottom: 28px;
        text-align: center;
        font-size: 25px;
        color: $primary;
        text-transform: uppercase;
        &:after {
          content: '';
          width: 181px;
          height: 4px;
          margin: 16px auto 0;
          display: block;
          background-color: $primary;
        }
      }
      .descp2 {
        text-align: start !important;
        font-size: 16px;
        color: rgb(44, 44, 44);
        text-align: center;
        line-height: 1.5;
        margin-bottom: 35px;
      }
      .descp3 {
        color: $blue;
        line-height: 1.667;
        text-align: center;
        font-size: 18px;
      }
      .button-box {
        display: flex;
        justify-content: center;
        .short-button {
          width: 200px;
        }
        .long-button {
          width: 300px;
        }
        .btn-blue {
          min-width: 162px;
        }
      }

      .sub-box1 {
        padding-bottom: 52px;
        border-bottom: 1px dotted rgb(201, 201, 201);
      }

      .sub-box2 {
        padding-top: 52px;
      }

      .opt-button-box {
        margin-top: 31px;
      }

      .agreeTnc-box {
        display: flex;
        justify-content: center;
        padding-left: 36px;
        font-size: 16px;
        font-weight: 200;
        color: rgb(122, 129, 144);
        margin-top: 20px;
        a {
          font-size: 14px;
          font-weight: 200;
          color: rgb(122, 129, 144);
          border-bottom: 1px solid rgb(122, 129, 144);
          padding: 0 0 1px;
        }
      }

      .checkbox {
        text-align: end;
        .el-checkbox__inner {
          width: 20px;
          height: 20px;
          border-radius: unset;
          border: 1px solid #0051ff;
          &::after {
            height: 14px;
            width: 6px;
            left: 6px;
            top: -2px;
          }
        }
      }
    }
  }

  .table-box {
    padding: 64px 50px 0 50px;

    /deep/.el-table {
      border-radius: 8px;
      tr {
        text-transform: uppercase;
      }
      .ftd {
        width: 50px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .table-title {
      font-size: 25px;
      color: $blue;
      text-transform: uppercase;
      line-height: 1;
      @include rtl-sass-value(text-align, left, right);
      margin-bottom: 5px;
      font-weight: 500;
      &:after {
        content: '';
        width: 181px;
        height: 4px;
        margin: 22px 0;
        display: block;
        background-color: $primary;
      }
    }

    .tab-demo {
      margin-bottom: 24px;

      li {
        display: inline-block;
        text-align: center;
        color: #000833;
        min-width: 120px;
        height: 46px;
        border-radius: 23px;
        line-height: 1.5;
        border: solid 1px #000d28;
        cursor: pointer;
        padding: 12px 20px;

        &:first-child {
          @include rtl-sass-prop(margin-right, margin-left, 10px);
        }

        &.active {
          color: $white;
          border: solid 1px transparent;
          background-color: #0051ff;
        }

        &:hover {
          border: solid 1px transparent;
        }
      }
    }

    .table-item {
      display: none;
      &.active {
        display: block;
      }
    }

    /deep/ .table-wrapper {
      .descp1 {
        font-size: 22px;
        line-height: 1.364;
        letter-spacing: normal;
        color: $white;
      }
      .table-welcome {
        margin-bottom: 64px;
      }
      .page-box {
        margin-bottom: 64px;
      }
    }
  }

  &.deposit20Bonus {
    .meta-box {
      /deep/ {
        .agreeTnc-box {
          color: #000;
          a {
            color: $future-blue;
            border-bottom: 1px solid $future-blue;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #depositBonus {
    .banner-container {
      .banner-text {
        font-size: 22px;
      }
    }
    .over-bonus-alert {
      text-align: center;
      margin-top: 25px;
      padding: 0 26px;
    }
    .meta-box {
      padding: 31px 24px 24px;
    }
  }
}

@media (max-width: 550px) {
  #depositBonus {
    .banner-container {
      .banner-text {
        font-size: 12px;
      }
    }
    .table-box {
      padding: 64px 20px 0 20px;
    }
  }
}
